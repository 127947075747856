(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/background/views/background.js') >= 0) return;  svs.modules.push('/components/banner_layouts/background/views/background.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.background=_svs.banner_layouts.background||{};
_svs.banner_layouts.background.templates=_svs.banner_layouts.background.templates||{};
svs.banner_layouts.background.templates.background = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "no-action";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"renderCrmAttributes")||(depth0 && lookupProperty(depth0,"renderCrmAttributes"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"targetOffer") : depth0),{"name":"renderCrmAttributes","hash":{},"data":data,"loc":{"start":{"line":6,"column":21},"end":{"line":6,"column":56}}}));
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"themeClass") || (depth0 != null ? lookupProperty(depth0,"themeClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"themeClass","hash":{},"data":data,"loc":{"start":{"line":9,"column":80},"end":{"line":9,"column":94}}}) : helper)));
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"dynamicActionTemplate")||(depth0 && lookupProperty(depth0,"dynamicActionTemplate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"actionType") : depth0),(depth0 != null ? lookupProperty(depth0,"actionConfig") : depth0),(depths[1] != null ? lookupProperty(depths[1],"trackingString") : depths[1]),{"name":"dynamicActionTemplate","hash":{},"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":29,"column":87}}}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"banner-background-brand-container\">\n                "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"emblem":true,"block":true,"brand":(depth0 != null ? lookupProperty(depth0,"brand") : depth0)},"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":35,"column":66}}}))
    + "\n              </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " \n<div class=\"banner f-content banner-background background-banner-type banner-background-"
    + alias4(((helper = (helper = lookupProperty(helpers,"height") || (depth0 != null ? lookupProperty(depth0,"height") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"height","hash":{},"data":data,"loc":{"start":{"line":2,"column":88},"end":{"line":2,"column":98}}}) : helper)))
    + "\n  "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"callToAction") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"actionType") : stack1),"===","no-action",{"name":"compare","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":81}}})) != null ? stack1 : "")
    + "\"\n  data-cmsid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":14},"end":{"line":4,"column":20}}}) : helper)))
    + "\"\n  data-cmstype=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":31}}}) : helper)))
    + "\"\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"targetOffer") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":6,"column":63}}})) != null ? stack1 : "")
    + "\n  data-impression=\"true\"\n  data-impression-tracking=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"trackingString") || (depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trackingString","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":46}}}) : helper)))
    + "\">\n  <div class=\"banner-background-container f-content f-content-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"themeClass") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":62},"end":{"line":9,"column":101}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-multi_image"),depth0,{"name":"components-content-image_v2-multi_image","hash":{"altText":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"productImage_WIDE") : stack1)) != null ? lookupProperty(stack1,"altText") : stack1),"title":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"productImage_WIDE") : stack1)) != null ? lookupProperty(stack1,"title") : stack1),"images":(lookupProperty(helpers,"array")||(depth0 && lookupProperty(depth0,"array"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"productImage_WIDE") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"productImage_BACKGROUND") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"productImage_BANNER_MOBILE") : stack1),{"name":"array","hash":{},"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":118}}})},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <div class=\"banner-background-overlay\">\n      <div class=\"banner-background-content banner-background-content-centered\">\n        <div class=\"banner-background-content-container\">\n          <div class=\"banner-background-content-holder\">\n            <section>\n               <div class=\"banner-background-textarea-background\">\n                 <h1 class=\"banner-background-title\" itemprop=\"headline\">\n                   "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"heading") || (depth0 != null ? lookupProperty(depth0,"heading") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"heading","hash":{},"data":data,"loc":{"start":{"line":22,"column":19},"end":{"line":22,"column":32}}}) : helper))) != null ? stack1 : "")
    + "\n                 </h1>\n                 <div class=\"margin-xs-bottom-1 f-summary\" itemprop=\"text\">\n                   <p class=\"banner-background-paragraph\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":25,"column":58},"end":{"line":25,"column":68}}}) : helper))) != null ? stack1 : "")
    + "</p>\n                 </div>\n                 <div class=\"banner-background-cta-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"callToActions") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":18},"end":{"line":30,"column":27}}})) != null ? stack1 : "")
    + "                 </div>\n              </div>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"brand") : depth0),"!==","Ingen",{"name":"compare","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":14},"end":{"line":37,"column":26}}})) != null ? stack1 : "")
    + "            </section>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n\n";
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-banner_layouts-background-background'] = svs.banner_layouts.background.templates.background;
})(svs, Handlebars);


 })(window);